.center_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

@activeColor:#428BE5;
@focusColor:#1872F6;
@centerBox:{
  display: flex;
  justify-content: center;
  align-items: center;
}
@hCenterBox:{
  display: flex;
  justify-content: center;
}
@vCenterBox:{
  display: flex;
  align-items: center;
}
@colBox:{
  display: flex;
  flex-direction: column;
}
