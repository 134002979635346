.con{
    width: 100%;
    // height: 716px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    &_nomore{
        font-size: 12px;
        color: #777;
        text-align: center;
        padding: 8px;
    }
    /*滚动条 start*/
    &::-webkit-scrollbar {
        width: 7px;
        height: 4px;
        background-color: #f5f5f5;
      }
      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: #fff;
      }
      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(245, 238, 238, 0.7);
      }
      &::-webkit-scrollbar-thumb:hover {
        border-radius: 3px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(245, 238, 238, 1);
      }
}
@primary-color: #428BE5;