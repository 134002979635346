.cve_list {
  padding: 8px 8px 0px;

  .cve_list_scroll {
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgba(245, 238, 238, 0.7);
    }
  }

  .cve_item {
    display: flex;
    padding: 9px 13px;
    border-radius: 10px;

    &_focus {
      background-color: @focusColor;

      .cve_title {
        font-size: 14px;
        position: relative;
        color: #fff !important;

        &:after {
          color: #fff !important;
        }
      }

      .cve_msg {
        color: #fff !important;
      }
    }

    &:hover {
      //  background-color: #F1F2F5;
      /* .cve_title {
       font-size: 14px;
       position: relative;
       color: #fff !important;
       &:after {
         color: #fff !important;
       }
     }
     .cve_msg{
       color: #fff !important;
     } */
    }

    .con_info {
      @vCenterBox();
      padding-left: 12px;
      width: 100%;
    }

    .con_icon {
      @centerBox();
      min-width: 42px;
      min-height: 42px;
      border-radius: 4px;
    }

    .cve_info {
      display: flex;
      flex-direction: column;
      padding: 2px 0px 2px 12px;

      width: 100%;

      .cve_title {
        font-size: 16px;
        position: relative;
        color: #000;

        &:after {
          content: attr(data-time);
          position: absolute;
          right: 0;
          font-size: 12px;
          color: #888;
        }
      }

      .cve_msg {
        font-size: 14px;
        color: #666;
        margin-top: 2px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        position: relative;

        &_opt {
          &::after {
            content: url(../assets/images/cve_opt.png);
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}

.total_content {
  // background-color: #fff;
  height: 100%;

  .content_bg {
    // @centerBox();
    display: flex;
    align-items: center;
    // background-image: url(../assets/images/bg1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    flex-direction: column;
    height: 100%;

    .welComeContent {
      height: 100%;
      @centerBox();
      flex-direction: column;
    }

    &_top {
      width: 100%;
      height: 56px;
      background-color: #fff;
      flex-shrink: 0;
      // position: absolute;
      // top: 0;
    }

    &_title {
      font-size: 32px;
      font-weight: 500;
      color: #666;
    }

    &_sub {
      color: #777;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 60px;
      color: #8E9298;
    }

    &_btn {
      margin-top: 120px;
      width: 120px;
      border-radius: 6px !important;
    }
  }

  .chat_bg {
    position: relative;
    height: 100%;
    // background-image: url(../assets/images/bg1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &::-webkit-scrollbar {
      display: none;
    }

    #scr_container {
      max-width: 748px;
      margin: 0 auto;
      width: 100%;
      min-width: 506px;
      // padding: 0 24%;
      // display: flex;
      // justify-content: center;
      // align-items: center;

      //&::-webkit-scrollbar {
      //  display: none;
      //}
      .chat_bg_content {
        max-width: 748px;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 14px;
        height: 100%;
        // margin: 0 auto;
      }
    }

    &_msg {
      display: flex;
      padding: 12px;

      &_icon {
        margin-right: 12px;
        min-width: 36px;
      }

      &_content {
        max-width: 80%;
        position: relative;

        .hasRead {
          position: relative;
          left: -30px;
          bottom: 50%;
          transform: translateY(-170%);
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #1872F6;
          display: flex;
          font-size: 12px;
          align-items: center;
          justify-content: center;
          color: #1872F6;
        }

        &_text {
          position: relative;
          padding: 8px;
          border-radius: 6px;
          //border-top-left-radius: 0px;
          min-height: 38px;
          background-color: #fff;
          word-break: break-all;
          word-wrap: break-word;

          .hongbao_remark {
            position: absolute;
            top: 22px;
            left: 50px;
            z-index: 1;
            width: 56%;
            font-size: 14px;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
          }

          span {
            // color: @activeColor;
          }

          .notifacation_title {
            color: #1872F6;
          }
        }

        &_time {
          position: absolute;
          left: -6px;
          bottom: -18px;
          font-size: 12px;
          transform: scale(.9);
          color: #999;
          width: 110px;
        }

        //.nick_magin {
        //  margin-top: 18px;
        //}

        // &_pic{
        //   margin-top: 24px;
        // }
        &_video {
          width: 240px;
          min-height: 120px;
          border-radius: 4px;
          overflow: hidden;

          video {
            width: 100%;
            height: 100%;
            object-fit: fill;
            border-radius: 6px;
          }

          // margin-top: 24px;
        }

        &_qute {
          display: flex;
          flex-direction: column;
          padding: 10px;

          .qute_content {
            padding-left: 8px;
            margin-bottom: 12px;
            border-left: 2px solid #1872F6;
            color: #222;
            font-size: 14px;

            .content {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
              overflow: hidden;
              word-break: break-all;
            }
          }
        }

        &_mer {
          display: flex;
          flex-direction: column;
          padding-left: 12px;
          font-size: 13px;
          padding: 10px 14px;

          .title {
            border-bottom: 2px solid #428BE5;
            margin-bottom: 4px;
          }

          .item {
            font-size: 12px;
            color: #999;
          }
        }

        &_card {
          padding: 8px 14px;
          font-size: 13px;
          cursor: pointer;
          min-width: 200px;

          .title {
            width: 100%;
            padding-bottom: 4px;
            border-bottom: 1px solid #999;
          }

          .desc {
            @vCenterBox();
            // padding: 12px 0;
            .card_nick {
              padding-left: 6px;
              color: #999;
            }
          }
        }

        &_map {
          width: 200px;
          //height: 100px;
          border-radius: 4px;
          //overflow: hidden;
          position: relative;
          top: -20px;
        }

        &_voice {
          padding-left: 14px;

          div {
            display: flex;
            cursor: pointer;
          }
        }

        &_file {
          padding-right: 42px;

          .file_container {
            @vCenterBox();

            img {
              width: 54px;
              height: 54px;
            }

            .anticon {
              font-size: 36px;
            }

            .file_info {
              display: flex;
              flex-direction: column;
              padding-left: 6px;
              font-size: 14px;
              color: #8E9298;

              div {
                &:first-child {
                  padding-bottom: 6px;
                  font-size: 16px;
                  color: #000;
                }
              }
            }
          }
        }

        .ant-image-img {
          border-radius: 4px;
        }

        .nick {
          //position: absolute;
          //top: 0;
          //left: 0;
          width: 240px;
          color: #999;
          font-size: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          overflow: hidden;
        }

        .pic_msg_time,
        .video_msg_time {
          position: absolute;
          bottom: 6px;
          right: 8px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          transform: scale(.9);
          width: 42px;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.4);
          text-align: center;
          border-radius: 4px;
        }

        .video_msg_time {
          bottom: auto;
          top: 6px;
        }
      }

      &_check {
        margin-top: 10px;

        .ant-radio-inner {
          transition: none !important;

          &::after {
            transition: none !important;
          }
        }

        .ant-radio-checked {
          .ant-radio-inner {
            transition: none !important;
            background-color: #1872F6 !important;

            &::after {
              transition: none !important;
              background-color: #1872F6 !important;
              content: "";
              width: 9px;
              height: 6px;
              background-image: url('../assets/images/vector.png');
              background-size: 100% 100%;
              margin: 0px !important;
              transform: translate(-50%, -50%);

            }
          }

        }

        .ant-checkbox {
          border-radius: 50%;

          .ant-checkbox-input {
            border-radius: 50%;

            .ant-checkbox-inner {
              border-radius: 50%;
            }
          }
        }
      }
    }


    &_omsg {
      position: relative;
      flex-direction: row-reverse;

      .chat_bg_msg_icon {
        margin-right: 0;
        margin-left: 12px;
      }

      .nick {
        left: auto;
        right: 0;
        text-align: right
      }

      .chat_bg_msg_content_text {
        background-color: #D2E4FF;
        border-radius: 10px;
        border-top-right-radius: 0px;

        .notifacation_title {
          color: #1872F6;
        }

        .msg-time {
          position: absolute;
          right: -15px;
          bottom: -18px;
          font-size: 12px;
          transform: scale(.9);
          color: #999;
          width: 110px;
        }
      }

      .chat_bg_msg_content_mer {
        padding-right: 48px;
      }
    }

    &_tips {
      text-align: center;
      font-size: 12px;
      color: #999;
      padding-top: 15px;

      b {
        font-weight: normal;
        color: @activeColor;
      }
    }

    &_nomore {
      text-align: center;
      padding: 12px 0;
      color: #999;
      font-size: 12px;
    }

    &_flag {
      @vCenterBox();
      margin-right: 8px;
      font-size: 12px;
    }

  }


  .notice_bg {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 8px;

    &_item {
      @vCenterBox();
      width: 100%;
      min-height: 102px;
      padding: 10px 16px;
      justify-content: space-between;
      border-radius: 10px;

      &:hover {
        background-color: #F1F2F5;
      }

      &_left {
        // @vCenterBox();
        display: flex;
        // align-items: center;
        flex: 1;

        .notice_friend {
          @colBox();
          margin-left: 12px;
          width: 100%;
          justify-content: space-between;

          &_top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_right {
              display: flex;
              height: 100%;

              .ant-btn {
                border-radius: 6px;
                padding: 0 14px;
                height: 26px;
                font-size: 14px;
                border-color: @activeColor;
                color: @activeColor;
                border-radius: 8px;
                height: 36px;

                &:hover {
                  background-color: #1872F6;
                  color: #fff;
                }
              }

              .apt_result {
                color: #777;
              }
            }
          }

          &_title {
            font-weight: 500;
            font-size: 16px;
          }

          &_sub {
            font-size: 14px;
            color: #000;
            margin: 4px 0px;
          }

          &_sub2 {
            font-size: 14px;
            color: #666;
          }
        }

        .notice_group {
          margin-left: 12px;
          font-size: 12px;
          width: 100%;
          justify-content: space-between;

          &_title {
            font-size: 14px;
            font-weight: 500;
          }

          &_sub {
            padding: 8px 0;

            span {
              color: @activeColor;
            }
          }

          &_res {
            color: #777;
            max-width: 90%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            word-break: break-all;
          }
        }
      }

      &_right {

      }

      /* &:hover {
        background-color: #F1F2F5;
      } */
    }
  }

  .group_bg {
    height: 100%;
    overflow-y: auto;
    padding: 0 8px;

    .group_item {
      @vCenterBox();
      padding: 8px 12px;
      border-radius: 10px;

      &_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 8px;
      }

      &_title {
        font-weight: 500;
      }

      &_sub {
        font-size: 12px;
        color: #999;
      }

      &:hover {
        background-color: #F1F2F5;
      }
    }
  }
}

.right_bar {
  // background-color: #eff4f5 !important;
  &_col {
    @vCenterBox();
    flex-direction: column;
    padding-top: 35px;
    cursor: pointer;

    &_icon {
      padding: 16px 0;
    }
  }
}

.chat_header {
  background-color: #fff !important;
  padding: 0 35px 0 22px !important;
  position: relative;
  height: 56px;
  // border-right: 1px solid #F4F4F4;
  &_box {
    @vCenterBox();
    justify-content: space-between;
    height: 56px;
    -webkit-app-region: no-drag;

    &_left {
      @vCenterBox();
      height: 56px;

      .cur_status {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        &_nick {
          height: 24px;
          font-size: 16px;
          color: #000;
          font-weight: 500;
        }

        &_update {
          @vCenterBox();
          height: 56px;

          .icon {
            display: inline-block;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #0ecc63;
          }

          .online {
            font-size: 12px;
            margin-left: 6px;
            margin-right: 12px;
            color: #8E9298;
          }

          .typing {
            font-size: 12px;
            color: #999;
          }

          .num {
            @vCenterBox();
            color: #999;
            font-size: 12px;

            &:last-child {
              margin-left: 12px;
            }
          }
        }
      }
    }

    &_right {
      display: flex;

      .chat_call {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .anticon {
        padding: 0 12px;
        font-size: 18px;
        color: #999;
        cursor: pointer;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  // &_cons{
  //   max-width: 90%;
  // }
}

.chat_footer {
  background-color: transparent !important;
  // padding: 22px !important;
  // max-width: 700px;
  width: 100%;
  min-width: 506px;
  margin: 0 auto;
  padding: 24px 10px;

  .time_wrap {
    display: flex;
    align-items: center;
    color: #8E9298;
    font-size: 16px;
    position: absolute;
    right: 140px;
    top: 50%;
    transform: translateY(-50%);

    .dot {
      margin-left: 2px;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: #FF1F8A;
    }
  }

  .chat_footer_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  }

  .input_div {
    width: 100%;
    margin-right: 15px;
    padding-top: 10px;
    padding-left: 50px;
    padding-right: 45px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.4;
    background-color: #fff;
    background-image: none;
    transition: all 0.3s;
    border-radius: 10px;
    position: relative;
    outline: none;
    white-space: pre-wrap;
    //white-space: nowrap;
    word-break: break-all;
    overflow: auto;
    // padding-right: 60px;
    cursor: text;
    height: 128px;
    // max-height: 20vh;
    // fix safari input
    -webkit-user-select: text;

    //&::-webkit-scrollbar {
    //  display: none;
    //}

    &:empty:before {
      content: attr(placeholder);
      color: rgb(169, 169, 169);
    }
  }

  .reply {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    color: #999;
    top: -54px;
    left: 0px;
    height: 54px;
    width: 632px;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &_left {
      display: flex;
      align-items: center;

      &_img {
        border-right: 2px solid #1872F6;
        width: 54px;
        display: flex;
        height: 32px;
        align-items: center;
        justify-content: center;

        img {
          height: 24px;
          width: 24px;
        }
      }

      &_main {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        height: 32px;
        justify-content: space-between;
        padding-left: 10px;

        &_top {
          color: #1872F6;
          height: 14px;
          line-height: 14px;
        }

        &_bottom {
          height: 14px;
          line-height: 14px;
          color: #8E9298;
        }
      }
    }

    &_right {
      padding-right: 22px;
    }

    .reply_text {
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      overflow: hidden;
    }

    .anticon {
      color: #ddd;
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .face_type_drop_box {
    display: flex;
    position: absolute;
    left: 0px;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    color: #8E9298;
    font-size: 20px;
    z-index: 1;
    width: 54px;
    //  background-color: #fff;
  }

  .suffix_container {
    display: flex;
    position: absolute;
    right: 80px;
    top: 50%;
    transform: translateY(-50%);

    .anticon {
      font-size: 18px;
      color: #999;
    }
  }

  .footer_mutil {
    // @centerBox();
    // position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 54px;
    border-radius: 10px;
    width: 520px;
    padding: 0 30px;

    img {
      width: 24px;
      height: 24px;

    }

    &_left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &_text {
        margin-left: 15px;
        color: #000;
        font-size: 16px;
      }
    }

    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #000;
      font-size: 16px;
      cursor: pointer;

      img {
        margin: 0 18px;
      }

      &_forward {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 124px;
        margin-right: 10px;

        &_text {
          height: 54px;
          line-height: 54px;
        }
      }

      &_del {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_text {
          height: 54px;
          line-height: 54px;
        }
      }
    }

    /*  .anticon{
       position: absolute;
       right: 0;
       top: -12px;
       color: #999;
       cursor: pointer;
     } */
  }
}


.msg_time_tip {
  .ant-tooltip-inner {
    min-height: auto;
    min-width: auto;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
  }
}


.right_user_information {
  width: 480px;
  background-color: #fff;
  // height: 100%;
  // height: calc(100vh);
  height: 100vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  // padding-top: 56px;
  display: flex;
  flex-direction: column;
  position: relative;

  .editPage_right_bar {
    background-color: #FFF;
    height: 100%;
    flex: 1 !important;

    &_top_tool {
      padding: 0 20px;
      height: 56px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: 100%;
      // position: fixed;
      right: 0px;
      // top: 0px;
      width: 480px;

      &_left {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          cursor: pointer;
          margin-right: 10px;
        }

        span {
          display: block;
          height: 40px;
          font-size: 16px;
          color: #000;
          line-height: 40px;
        }
      }

      &_right {
        color: #1872F6;
        font-size: 16px;
        cursor: pointer;
        width: 50px;
        height: 54px;
        line-height: 54px;
        background-color: transparent;
      }

    }

    &_input {
      width: 100%;
      padding: 20px;

      .ant-input {
        &:focus {
          outline: 1px solid #1872F6;
          box-shadow: none;
          //  border: 1px solid #123456;
        }
      }
    }

    .publicPage_input {
      .ant-input {
        min-height: 584px;
        border: none !important;

        &:focus {
          outline: none;
          box-shadow: none;
          //  border: 1px solid #123456;
        }
      }
    }

    .line {
      height: 8px;
      width: 100%;
      background-color: #F4F4F4;
    }

    &_operation {
      display: flex;
      flex-direction: column;
      padding: 20px 8px;

      &_item {
        display: flex;
        padding: 0 24px;
        align-items: center;
        height: 64px;
        border-radius: 10px;

        &:hover {
          background-color: #F1F2F5;
        }

        img {
          height: 24px;
          width: 24px;
          margin-right: 24px;
        }

        .text {
          font-size: 16px;
          color: #222;
          height: 24px;
          line-height: 24px;
        }
      }
    }
  }

  .right_bar_top_tool {
    height: 56px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    // position: fixed;
    // right: 0px;
    // top: 0px;
    width: 480px;
    z-index: 20;

    &_left {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        cursor: pointer;

      }

      span {
        font-size: 16px;
        color: #000;
        line-height: 16px;

      }
    }

    &_right {
      padding-right: 8px;

      img {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }

  .avatarBox {
    display: flex;
    // background-color: #7b7367;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 320px;
    background-size: 100% 100%;
    position: relative;

    .glass {
      width: 100%;
      height: 320px;
      position: absolute;
      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(17px);
      border-radius: 0px;
    }

    .avatar {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: #fff;
      margin-bottom: 10px;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 9;
      /*  img{
         width: 100%;
         height: 100%;
       } */
    }

    .avatarBox_title {
      font-size: 24px;
      font-weight: 500;
      //color: #fff;
      height: 24px;
      margin-bottom: 10px;
      z-index: 9;
    }

    .avatarBox_subtitle {
      //color: #fff;
      font-size: 16px;
      z-index: 9;
    }


  }

  .right_bar_infoBox {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-top: 8px;
    padding-bottom: 8px;

    .vip-info-container {
      padding: 15px 28px; // 与其他信息保持一致的左右边距
      background-color: #f5f5f5; // 轻微的背景颜色以区别其他部分
      border-radius: 10px; // 统一圆角
      margin: 10px 0;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // 增加阴影效果
    }

    .vip-info-title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }

    .vip-info-item {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;
      font-size: 14px;
      color: #666;
    }

    .vip-info-item span:first-child {
      font-weight: bold;
      color: #333;
    }

    .vip-info-item span:last-child {
      color: #000;
    }


    // background-color: #F4F4F4; d
    .right_bar_infoBox_item {
      height: 84px !important;
      display: flex;
      align-items: center;
      padding: 0 28px;
      height: 100%;

      &_left {
        margin-right: 20px;
        padding-bottom: 20px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      &_right {
        display: flex;
        flex-direction: column;

        span {
          &:nth-child(1) {
            color: #8E9298;
            font-size: 14px;
          }

          &:nth-child(2) {
            font-size: 16px;
            color: #222;
          }
        }
      }
    }

    .introduce_item {
      display: flex;
      align-items: flex-start;
      padding: 20px 28px 0;
      min-height: 64px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
        margin-top: 1px;
      }

      span {
        font-size: 16px;
        color: #222;
      }
    }

    .right_bar_infoBox_item.notifacation {
      height: 64px;
      margin: 0 8px;
      padding: 0 20px;

      border-radius: 10px;
      justify-content: space-between;

      &:hover {
        background-color: #F1F2F5;
      }

      .right_bar_infoBox_item_left {
        padding-bottom: 0px;
        margin-right: 20px;
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
          width: 24px;
          height: 24px;
        }

        span {
          display: block;
          font-size: 16px;
          color: #222;
          height: 24px;
          line-height: 24px;
        }
      }

      .right_bar_infoBox_item_right {

      }
    }
  }


  .group_bar {
    background-color: #fff;

    .right_bar_infoBox {
      &_item {
        &_left {
          padding: 0px !important;

          img.notificationImg {
            margin-bottom: 20px;
          }

          .private_chat {
            display: flex;
            flex-direction: column;

            &_text {
              color: #8E9298;
              font-size: 12px;
            }
          }
        }

        &_right {
          span {
            &:nth-child(1) {
              color: #000;
              font-size: 16px;
            }
          }

          span.public {
            color: #8E9298 !important;
            font-size: 14px;
          }

          div.notificationBox {
            display: flex;
            align-items: center;
            cursor: pointer;

            div.text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 1;
              max-width: 350px;
            }

            .anticon {
              width: 30px;
            }
          }
        }
      }
    }

    .tabBox {
      background-color: #fff;

      .group_user_list {
        padding: 0 8px;

        &_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 72px;
          padding: 0 13px;
          border-radius: 10px;

          &:hover {
            background-color: #F1F2F5;
          }

          &_left {
            display: flex;
            align-items: center;

            &_infoBox {
              display: flex;
              flex-direction: column;
              margin-left: 10px;

              &_name {
                color: #000;
                font-size: 16px;
              }

              &_online {
                color: #666;
                font-size: 14px;
              }
            }
          }

          &_right {
            color: #666;
            font-size: 14px;
          }
        }
      }
    }
  }

  .tabBox {
    .line {
      height: 8px;
      width: 100%;
      background-color: #F4F4F4;
    }

  }


  .inviteBox {
    position: fixed;
    bottom: 30px;
    right: 25px;
    background-color: #1872F6;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }


  .editPage_group {
    &_top {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      position: fixed;
      top: 0px;
      right: 0px;
      background-color: #fff;
      width: 480px;

      &_left {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          cursor: pointer;
          margin-right: 10px;
        }

        span {
          display: block;
          height: 40px;
          font-size: 20px;
          color: #000;
          line-height: 40px;
        }
      }

      &_right {
        color: #1872F6;
        background-color: transparent;
        font-size: 16px;
        width: 50px;
        height: 54px;
        line-height: 54px;
        cursor: pointer;
      }
    }

    &_input {
      height: 264px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 36px;

      .uploadBox {
        height: 264px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .imgBox {
          background: linear-gradient(180deg, #86B7FF 0%, #4B94FF 100%);
          background-size: cover;
          height: 120px;
          width: 120px;
          border-radius: 50%;
          @centerBox();
          margin-bottom: 30px;
          cursor: pointer;

          img {
            width: 48px;
            height: 48px;
          }
        }

        .create_info_box_input {
          height: 54px;
          width: 100%;
          padding: 0 42px;

          .ant-input-affix-wrapper {
            height: 100%;
            border-radius: 8px;
            border: 1px solid #E3E7EC;
          }
        }
      }
    }

    &_operation {
      display: flex;
      flex-direction: column;
      // margin-top: 36px;
      padding: 20px 8px;

      &_item {
        display: flex;
        padding: 0 24px;
        align-items: center;
        height: 64px;
        border-radius: 10px;

        &:hover {
          background-color: #F1F2F5;
        }

        img {
          height: 24px;
          width: 24px;
          margin-right: 24px;
        }

        .text {
          font-size: 16px;
          color: #222;
          height: 24px;
          line-height: 24px;
        }
      }
    }
  }

  .RightAssistantInfo_item {
    display: flex;
    height: 64px;
    align-items: center;
    margin: 0 8px;
    padding: 0 20px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f1f2f5;
    }

    img {
      height: 24px;
      width: 24px;
      margin-right: 20px;
    }

    span {
      font-size: 16px;
    }
  }

  .RightAssistantInfo_switch {
    display: flex;
    align-items: center;
    height: 87px;
    justify-content: space-between;
    margin: 0 8px;
    padding: 0 20px;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f1f2f5;
    }

    &_left {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }

      &_r {
        display: flex;
        flex-direction: column;

        span {
          &:nth-child(1) {
            font-size: 16px;
          }

          &:nth-child(2) {
            font-size: 12px;
            color: #8E9298;
          }
        }
      }
    }
  }


}

.editPage_right_bar {
  background-color: #FFF;
  height: 100%;
  flex: 1 !important;

  &_top_tool {
    padding: 0 20px;
    height: 56px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    // position: fixed;
    right: 0px;
    // top: 0px;
    width: 480px;

    &_left {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin-right: 10px;
      }

      span {
        display: block;
        height: 40px;
        font-size: 16px;
        color: #000;
        line-height: 40px;
      }
    }

    &_right {
      color: #1872F6;
      font-size: 16px;
      cursor: pointer;
      width: 50px;
      height: 54px;
      line-height: 54px;
      background-color: transparent;
    }

  }

  &_input {
    width: 100%;
    padding: 20px;

    .ant-input {
      &:focus {
        outline: 1px solid #1872F6;
        box-shadow: none;
        //  border: 1px solid #123456;
      }
    }
  }

  .publicPage_input {
    .ant-input {
      min-height: 584px;
      border: none !important;

      &:focus {
        outline: none;
        box-shadow: none;
        //  border: 1px solid #123456;
      }
    }
  }

  .line {
    height: 8px;
    width: 100%;
    background-color: #F4F4F4;
  }

  &_operation {
    display: flex;
    flex-direction: column;
    padding: 20px 8px;

    &_item {
      display: flex;
      padding: 0 24px;
      align-items: center;
      height: 64px;
      border-radius: 10px;

      &:hover {
        background-color: #F1F2F5;
      }

      img {
        height: 24px;
        width: 24px;
        margin-right: 24px;
      }

      .text {
        font-size: 16px;
        color: #222;
        height: 24px;
        line-height: 24px;
      }
    }
  }
}

.create_group {
  position: fixed;
  width: 480px;
  top: 0px;
  z-index: 9;
  background-color: #fff;
}

.c_create_group {
  left: 0px;
}

.add_create_group {
  right: 0px;
}

.Layout {
  background-color: #fff !important;
}

.homeSidebar_top {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 56px;

  &_left {
    width: 40px;
    margin-right: 16px;

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

  &_right {
    flex: 1;
    padding-right: 12px;
    font-size: 20px;

    .ant-input-affix-wrapper {
      border-radius: 10px;
      height: 40px;
      border: none !important;
      background-color: #F1F2F5;
      -webkit-app-region: no-drag;
    }

    .ant-btn {
      -webkit-app-region: no-drag;
    }

    .ant-input {
      background-color: #F1F2F5;
    }

    .ant-input-prefix {
      color: #8E9298;
    }

  }
}

.is_select_wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px;

  .is_select_item {
    display: flex;
    align-items: center;
    background: #F1F2F5;
    border-radius: 100px;
    height: 32px;
    padding-right: 3px;
    margin-right: 8px;
    margin-bottom: 8px;

    &_name {
      color: #000;
      font-size: 16px;
      padding: 0 14px 0px 10px;
    }

    .img {
      margin-right: auto;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }

      &:hover {
        background-color: #fff;
      }
    }
  }
}


.searchBox {
  height: 42px;
  padding: 0 12px;

  .ant-input-affix-wrapper {
    height: 100%;
    border: none;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.line {
  height: 10px;
  background: #F4F4F4;
  width: 100%;
}

.next_box {
  position: absolute;
  cursor: pointer;
  bottom: 30px;
  right: 24px;
  background-color: #1872F6;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
}

.create_info_box {
  display: flex;
  flex-direction: column;

  .uploadBox {
    height: 264px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .imgBox {
      background: linear-gradient(180deg, #86B7FF 0%, #4B94FF 100%);
      background-size: cover;
      background-repeat: no-repeat;
      height: 120px;
      width: 120px;
      border-radius: 50%;
      @centerBox();
      margin-bottom: 30px;
      cursor: pointer;

      img {
        width: 48px;
        height: 48px;
      }
    }

    .create_info_box_input {
      height: 54px;
      width: 100%;
      padding: 0 42px;

      .ant-input-affix-wrapper {
        height: 100%;
        border-radius: 8px;
        border: 1px solid #E3E7EC;
      }
    }
  }

  &_listBox {
    display: flex;
    flex-direction: column;

    &_num {
      padding: 10px 25px;
      color: #1872F6;
      font-size: 16px;
    }

    &_content {
      padding: 0 8px;

      &_item {
        display: flex;
        height: 72px;
        align-items: center;
        padding: 0 13px;

        .info {
          margin-left: 10px;
          display: flex;
          flex-direction: column;

          &_name {
            color: #000;
            font-size: 16px;
          }

          &_online {
            color: #666;
            font-size: 14px;
          }
        }
      }
    }
  }
}


.setting_page {

  &_top {
    display: flex;
    align-items: center;
    padding: 0 15px 0px 8px;
    height: 56px;
    // justify-content: center;
    font-size: 16px;

    &_left {
      width: 40px;
      margin-right: 16px;

      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }

    &_right {
      margin-left: auto;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .avatarBox {
    display: flex;
    // background-color: #7b7367;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 320px;
    background-size: 100% 100%;
    position: relative;

    .glass {
      width: 100%;
      height: 320px;
      position: absolute;
      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(17px);
      border-radius: 0px;
    }

    .avatar {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: #fff;
      margin-bottom: 10px;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 9;
      /*  img{
         width: 100%;
         height: 100%;
       } */
    }

    .avatarBox_title {
      font-size: 24px;
      font-weight: 500;
      //color: #fff;
      height: 24px;
      margin-bottom: 10px;
      z-index: 9;
    }

    .avatarBox_subtitle {
      //color: #fff;
      font-size: 16px;
      z-index: 9;
    }


  }

  .right_bar_infoBox {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-top: 8px;
    padding-bottom: 8px;
    // background-color: #F4F4F4; d
    .right_bar_infoBox_item {
      height: 84px !important;
      display: flex;
      align-items: center;
      padding: 0 28px;
      height: 100%;

      &_left {
        margin-right: 20px;
        padding-bottom: 20px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      &_right {
        display: flex;
        flex-direction: column;

        span {
          &:nth-child(1) {
            color: #8E9298;
            font-size: 14px;
          }

          &:nth-child(2) {
            font-size: 16px;
            color: #222;
          }
        }
      }
    }

    .introduce_item {
      display: flex;
      align-items: flex-start;
      padding: 20px 28px 0;
      min-height: 64px;

      img {
        width: 24px;
        height: 24px;
        //margin-right: 20px;
        margin-top: 1px;
      }

      span {
        font-size: 16px;
        color: #222;
      }
    }
  }

  &_menu {
    display: flex;
    flex-direction: column;
    padding: 8px;

    &_item {
      display: flex;
      align-items: center;
      padding: 0 20px;
      height: 64px;
      font-size: 16px;
      color: #000;
      border-radius: 10px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }

      &:hover {
        background: #F1F2F5;
      }

      .language {
        margin-left: auto;
        color: #8E9298;
      }
    }
  }

  .editInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    .avatarBox {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-size: cover;
      margin: 40px 0px 30px;
      cursor: pointer;
    }

    .info_form {
      width: 100%;
      margin-bottom: 20px;

      &_title {
        font-weight: 500;
        font-size: 16px;
        //margin-bottom: 5px;
      }

      .ant-input {
        //margin-top: 18px;
        border-radius: 8px;
        border-color: #E3E7EC;
        // border: none !important;
        outline-style: none;

        &:focus {
          outline: none;
          //  box-shadow: none;
          //  border-color: #E3E7EC !important;
        }
      }
    }

    .phone_wrap {
      //margin-top: 80px;
      &_name {
        font-weight: 500;
        font-size: 16px;
        //margin-bottom: 8px;
      }

      &_tips {
        margin: 20px 0px;
        font-size: 14px;
        color: #8E9298;
        line-height: 20px;

        span {
          &:nth-child(2) {
            color: #FF2330;
          }
        }
      }

      &_limit {
        font-size: 14px;
        color: #8E9298;
        margin-bottom: 30px;
      }
    }

  }

  .chat_bg_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 7px;
    cursor: pointer;

    &_item {
      width: 152px;
      height: 152px;
      background: #EEF1F6;
      margin-right: 4px;
      margin-bottom: 4px;

      &:nth-child(3n+3) {
        margin-right: 0px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .active {
      border: 2px solid #1872F6;
    }
  }

  .blacklist_content {
    display: flex;

    &_item {
      width: 100%;
      margin: 0 8px;
      padding: 0 14px;
      display: flex;
      height: 72px;
      align-items: center;
      border-radius: 10px;

      &:hover {
        background-color: #F1F2F5;
      }

      &_main {
        margin: 0 10px;

        div {
          font-size: 16px;
          color: #000;

          &:nth-child(2) {
            font-size: 14px;
            color: #666;
          }
        }
      }
    }
  }

  .language_content {
    &_item {
      display: flex;
      align-items: center;
      margin: 0 8px;
      border-radius: 10px;
      padding: 0px 15px;
      height: 64px;

      &:hover {
        background-color: #F1F2F5;
      }

      &_main {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        div {
          font-size: 16px;
          color: #000;

          &:nth-child(2) {
            font-size: 14px;
            color: #8E9298;
          }
        }
      }

      .ant-radio-inner {
        transition: none !important;

        &::after {
          transition: none !important;
        }
      }

      .ant-radio-checked {
        .ant-radio-inner {
          transition: none !important;
          background-color: #1872F6 !important;

          &::after {
            transition: none !important;
            background-color: #1872F6 !important;
            content: "";
            width: 9px;
            height: 6px;
            background-image: url('../assets/images/vector.png');
            background-size: 100% 100%;
            margin: 0px !important;
            transform: translate(-50%, -50%);

          }
        }

      }
    }
  }

}

.global_search {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_top {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 8px 21px 8px 8px;
    flex-shrink: 0;

    &_left {
      margin-right: 20px;
    }

    &_center {
      flex: 1;

      .ant-input-affix-wrapper {
        border-radius: 10px;
        height: 40px;
        border: none !important;
        background-color: #F1F2F5;
        -webkit-app-region: no-drag;
      }

      .ant-btn {
        -webkit-app-region: no-drag;
      }

      .ant-input {
        background-color: #F1F2F5;
      }

      .ant-input-prefix {
        color: #8E9298;
      }
    }
  }

  .initialContent {
    display: flex;
    flex-direction: column;

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;

      div {
        color: #8E9298;
        font-size: 16px;
      }

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    &_content {
      margin: 0 8px;

      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 12px 0px 13px;
        height: 48px;

        &_left {
          display: flex;

          div {
            width: 24px;
            height: 24px;
            background: #C1C4C8;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 24px;
              height: 24px;
            }

            margin-right: 10px;
          }

          span {
            font-size: 16px;
            color: #000;
          }
        }

        &_right {
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;

          }
        }
      }

    }
  }

  .searchComponent_wrap {
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .searchComponent {
    // height: calc(100vh - 54px);
    // height: 100%;
    // flex: 1;
    // overflow-y: auto;
    &_item {
      &_title {
        padding: 14px 21px;
        font-size: 16px;
        color: #000;
      }

      &_con {
        margin: 0 8px;
        padding: 0 13px;
        height: 72px;
        display: flex;
        align-items: center;
      }

      &_showMore {
        height: 50px;
        line-height: 50px;
        padding: 0 21px;
        font-size: 16px;
        color: #8E9298;

        span {
          cursor: pointer;
        }
      }
    }

    .chat_item {
      &_con {
        &_c {
          display: flex;
          flex-direction: column;

          span {
            &:nth-child(1) {
              font-size: 16px;
            }

            &:nth-child(2) {
              font-size: 14px;
              color: #666;
            }
          }
        }

      }
    }

  }

}


.chat_bg_msg_content_voice {
  background-color: #D2E4FF;
  border-radius: 6px;
  border-top-right-radius: 0px;
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .spinner {
    display: flex;
    margin: 0 8px;
    align-items: center;

    .lin {
      background-color: #1872F6;
      height: 14px;
      width: 3px;
      border-radius: 20px;
      margin-right: 3px;
      display: inline-block;

      /*  &:nth-child(3n+4){
          -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
       } */
    }

    .lin1 {
      height: 6px;
    }

    .lin2 {
      height: 12px;
    }

    .lin3 {
      height: 8px;
    }

    .lin4 {
      height: 6px;
    }

    .lin4 {
      height: 5px;
    }

    .lin5 {
      height: 3px;
    }

    .lin6 {
      height: 4px;
    }

    .lin7 {
      height: 6px;
    }

    .lin8 {
      height: 8px;
    }

    .lin9 {
      height: 5px;
    }

    .lin10 {
      height: 10px;
    }

    .lin11 {
      height: 9px;
    }

    .lin12 {
      height: 10px;
    }

    .lin13 {
      height: 12px;
    }

    .lin14 {
      height: 13px;
    }

    .lin15 {
      height: 14px;
    }

    .lin16 {
      height: 10px;
    }

    .lin17 {
      height: 8px;
    }

    .lin18 {
      height: 6px;
    }

    .lin19 {
      height: 8px;
    }

    .lin20 {
      height: 10px;
    }

    .lin.lin-animate {
      -webkit-animation: sk-stretchdelay 3s infinite ease-in-out;
      animation: sk-stretchdelay 3s infinite ease-in-out;
    }

    @-webkit-keyframes sk-stretchdelay {
      0%, 40%, 100% {
        -webkit-transform: scaleY(0.2)
      }
      20% {
        -webkit-transform: scaleY(1.0)
      }
    }

    @keyframes sk-stretchdelay {
      0%, 40%, 100% {
        transform: scaleY(0.2);
        -webkit-transform: scaleY(0.4);
      }
      20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
      }
    }
  }

}

.chat_bg_msg_hongbao {

  .chat_bg_msg_content {
    max-width: 100%;
    text-align: center;
    font-size: 12px;
    color: #999;
    padding-top: 5px;
  }

  b {
    font-weight: normal;
    color: #428BE5;
  }
}

.ant-tabs-nav-list {
  width: 100% !important;
  display: flex;
  border-bottom: 1px solid #fff;

  .ant-tabs-tab {
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .ant-tabs-tab-active {
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 3px;
      width: 40px;
      position: absolute;
      bottom: 0px;
      background-color: #1872F6;
      border-radius: 3px;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.search_right_information {
  width: 480px;
  background-color: #fff;
  // height: 100%;
  height: calc(100vh);
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  &_header {
    height: 56px;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin: 0px 10px 0px 16px;
      cursor: pointer;
    }

    span {
      font-size: 16px;
      color: #000;
    }
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid #F4F4F4;
  }

  .search_input_wrap {
    width: 100%;
    padding: 2px 8px;

    .ant-input-affix-wrapper {
      border: 1px solid #1872F6 !important;
    }

    .ant-input {
      border: 1px solid transparent !important;
    }
  }
}


.private_chat_text {
  color: #8E9298;
  font-size: 12px;
}

.AssistantContent {
  // width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;

  &_top {
    flex-shrink: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 15px;
    font-size: 16px;
    justify-content: space-between;

    &_left {
      display: flex;
      align-items: center;
    }

    .avatarBox {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(180deg, #8ABAFF 0%, #2F83FF 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      cursor: pointer;

      img {
        width: 25px;
        height: 22px;
      }
    }

    .moreImg {
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &_content {
    height: 100%;
    padding: 20px 0px;
    overflow: auto;

    &_list {
      max-width: 748px;
      margin: 0 auto;
      width: 100%;

      .AssistantContent_content_list_wrapp {
        display: flex;
        flex-direction: column;
        align-items: center;

        .time {
          background-color: #fff;
          display: inline-block;
          padding: 10px;
          max-width: 180px;
          margin-bottom: 20px;
          border-radius: 6px;
        }
      }

      &_item {
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        // min-height: 100px;
        padding: 20px 15px;

        &_title {
          color: #888;
          font-size: 14px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            &:nth-child(2) {
              cursor: pointer;

              &:hover {
                color: #1872F6;
              }
            }
          }

        }

        &_con {
          background: #F1F2F5;
          border-radius: 10px;
          padding: 13px 17px 13px 22px;
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 22px;
        }

        &_main {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          .video {
            width: 240px;
            min-height: 120px;
            border-radius: 4px;
            overflow: hidden;

            video {
              width: 100%;
              height: 100%;
              object-fit: fill;
              border-radius: 6px;
            }
          }

          .file {
            .file_container {
              @vCenterBox();

              img {
                width: 54px;
                height: 54px;
              }

              .anticon {
                font-size: 36px;
              }

              .file_info {
                display: flex;
                flex-direction: column;
                padding-left: 6px;
                font-size: 14px;
                color: #8E9298;

                div {
                  &:first-child {
                    padding-bottom: 6px;
                    font-size: 16px;
                    color: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &_footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .createButton {
      display: flex;
      align-items: center;
      height: 54px;
      padding: 0 30px;
      background-color: #fff;
      min-width: 208px;
      max-width: 300px;
      border-radius: 10px;
      justify-content: center;
      font-size: 16px;
      margin-bottom: 26px;
      cursor: pointer;

      span {
        margin-left: 18px;
      }
    }
  }
}


@media screen and (max-width: 1200px) {
  .home_sider {
    width: 400px !important;
    min-width: 400px !important;
    max-width: 400px !important;
  }

  .setting_page {
    .avatarBox {
      //height: 180px !important;
      .avatar {
        width: 100px !important;
        height: 100px !important;
      }

      //.glass {
      //  height: 180px !important;
      //}
    }

    .chat_bg_content_item {
      width: 92px !important;
      height: 92px !important;
    }

    .right_bar_infoBox {
      .right_bar_infoBox_item {
        height: 60px !important;
      }
    }

    .setting_page_menu {
      .setting_page_menu_item {
        height: 54px !important;
      }
    }

  }
}


@media screen and (max-width: 992px) {
  .home_sider {
    width: 30px !important;
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .setting_page {
    .avatarBox {
      //height: 180px !important;
      .avatar {
        width: 100px !important;
        height: 100px !important;
      }

      //.glass {
      //  height: 180px !important;
      //}
    }

    .chat_bg_content_item {
      width: 92px !important;
      height: 92px !important;
    }

    .right_bar_infoBox {
      .right_bar_infoBox_item {
        height: 60px !important;
      }
    }

    .setting_page_menu {
      .setting_page_menu_item {
        height: 54px !important;
      }
    }
  }


}

.LazyLoad {
  display: inline;
}
