@import "@/styles/extends.less";

.msg_type_drop {
  min-width: 200px !important;
  .ant-dropdown-arrow {
    // border-color: #353940 !important;
  }
  .input_menu.upload_card_wrap{
    background-color: #fff;
    height: 160px;
    width: 200px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(106, 132, 169, 0.2);
    padding: 8px 0px !important;
    .ant-upload{
      width: 100%;
    }
  }

  .input_menu {
    // background-color: #353940 !important;
    padding: 0 !important;
    border-radius: 8px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &_item{
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      color: #000;
      font-size: 16px;
      cursor: pointer;
      width: 100%;
      background-color: #fff;
      img{
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      &:hover{
        background-color: #F1F2F5;
      }

    }

    .ant-dropdown-menu-title-content {
      // color: #000;

      .ant-upload {
        color: #000;
      }
    }

    .ant-dropdown-menu-item-active {
      background-color: @focusColor !important;
    }
  }
}

.tool_top_drop{
  background-color: #fff !important;
  border-radius: 8px !important;
  width: 230px;
  .ant-dropdown-menu{
    background-color: #fff !important;
    color: #000 !important;
    .ant-dropdown-menu-item{
      height: 48px;
      .ant-dropdown-menu-title-content{
        color: #000 !important;
        font-size: 16px;
      }
    }
    .ant-dropdown-menu-item-active {
      background-color:  #F1F2F5 !important;
    }

  }

}

.tool_more_drop{
  background-color: #fff !important;
  border-radius: 8px !important;
  width: 230px;
  .ant-dropdown-menu{
     background-color: #fff !important;
     border-radius: 8px !important;
     width: 230px;
      .ant-dropdown-menu-title-content{
           color: #000 !important;
           font-size: 16px;
        }
      .ant-dropdown-menu-item{
        height: 48px !important;
      }

      &_item{
        height: 48px;

      }

      .ant-dropdown-menu-item-active {
       background-color:  #F1F2F5 !important;
    }
  }
}

.face_type_drop {
  .ant-dropdown-arrow {
    display: none;
  }

  .face_container {
    background-color: #fff;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    width: 270px;
    flex-wrap: wrap;

    .face_item {
      @vCenterBox();
      border-radius: 2px;
      padding: 6px;

      &:hover {
        background-color: #eaeaea;
      }
    }
  }
}

.global_loading {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}

.profile {
  &_header {
    background-color: #fff;
    border-bottom: 1px solid #dedfe0;
    padding: 0 16px;
  }

  &_sider {
    border-right: 1px solid #dedfe0;

    &_menu {
      &_item {
        padding: 16px;

        &:hover {
          background-color: @focusColor;
        }
      }
    }
  }

  &_content {
    background-color: #fff;

    &_bl {
      overflow-y: auto;

      &_item {
        @vCenterBox();
        justify-content: space-between;
        padding: 12px;
        padding-right: 48px;

        .item_left {
          @vCenterBox();

          .nick {
            margin-left: 12px;
          }
        }

        &:hover {
          background-color: @focusColor;
        }
      }
    }
  }

  .personal_setting{
    &_item{
      padding: 24px;
      padding-bottom: 0;
      .title{
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 12px;
      }
    }
  }
}

/* .login_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100vh;
  .login_wapper:extend(.center_box) {
    .center_container {
      display: flex;
      min-height: 420px;
      .left_container {
        text-align: center;
        width: 354px;
        margin-right: 72px;

        .title {
          font-size: 22px;
          font-weight: 500;
          padding-top: 43px;
        }

        .sub_title {
          font-size: 12px;
          color: #777;
        }

        img {
          width: 100%;
        }
      }
    }
  }
  .login_bottom {
    height:30px;
    width: 100%;
    background-color: #438be5;
  }
}

.login_form {
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0 ,0, 0.1);
  padding: 47px 44px;
  position: relative;
  width: 325px;

  .form_title {
    font-size: 16px;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 24px;

    .sub_title {
      font-size: 12px;
      color: @activeColor;
    }
  }

  .sub_tip {
    font-size: 13px;

    span {
      color: @activeColor;
    }
  }

  .name_input {
    display: flex;
    margin-top: 24px;

    .name_lable {
      font-size: 13px;
      font-weight: 500;
      width: 110px;
      height: 32px;
      line-height: 32px;
      border-bottom: 1px solid #bbbfc3;
      margin-right: -1px;
    }
  }

  .access_bottom {
    font-size: 12px;
    margin-top:4px;
    display: flex;
    justify-content:space-between;
    span{
      font-size: 13px;
      color: #428BE5;
      cursor: pointer
    }
  }

  .loading_spin {
    @centerBox();
    height: 100%;
  }

  .ant-input-group.ant-input-group-compact {
    display: flex;
  }

  .ant-input,
  .ant-select,
  .ant-input-password,
  .ant-input-suffix {
    font-size: 13px;
    height: 32px;
    border-bottom: 1px solid #bbbfc3 !important;
  }

  .ant-input-suffix {
    margin-left: -1px;
  }

  .ant-input-affix-wrapper {
    padding: 0;
  }

  .ant-btn-primary {
    width: 100%;
    border-radius: 4px;
  }

  .ant-form-item-label > label {
    font-size: 13px;
  }

  .ant-checkbox-wrapper {
    font-size: 12px;
  }

  .diver {
    border-right: 1px solid #bbbfc3;
    margin: 12px 0;
  }

  .no_mb {
    margin-bottom: 0;
  }

  .primary {
    color: @activeColor;
    cursor: "pointer";
  }
} */


 .login_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100vh;
  .login_wapper {
    display: flex;
    justify-content: space-between;
    height: 100%;
      .left_container {
        text-align: center;
        width: 50%;
        height: 100%;
        margin-right: 100px;
        .title {
          font-size: 22px;
          font-weight: 500;
          padding-top: 43px;
        }
        .sub_title {
          font-size: 12px;
          color: #777;
        }
        img {
          width: 100%;
          height: 100%;
        }
    }
    .right_container{
      width: 50%;
      position: relative;
      .copyrightBox{
        color: #C1C4C8;
        font-size: 12px;
        position: absolute;
        bottom: 30px;
        left: 100px;
      }
    }

  }
  .login_bottom {
    height:30px;
    width: 100%;
    background-color: #438be5;
  }
}

.login_form {
  position: relative;
  width:396px;
  height: 100%;
  margin-top: -8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .form_logo{
    width: 76px;
    //height: 84px;
    margin-bottom: 30px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .form_backImg{
    width: 24px;
    height: 24px;
    margin-bottom: 50px;
     img{
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .form_title {
    font-size: 40px;
    font-weight: 500;
    padding-top: 0;
    line-height: 40px;
    margin-bottom: 30px;
    // padding-bottom: 24px;

  /*   .sub_title {
      font-size: 12px;
      color: @activeColor;
    } */
  }

  .sub_tip {
    font-size: 13px;

    span {
      color: @activeColor;
    }
  }
  .forgetBox{
    text-align: right;
    display: inline-block;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8E9298;
    margin: 20px 0px 30px;
    span{
       cursor: pointer;
       &:hover{
        color: #1872F6;
      }
    }
  }

  .name_input {
    display: flex;
    margin-top: 24px;
    .name_lable {
      font-size: 13px;
      font-weight: 500;
      width: 0px;
      height: 54px;
      line-height: 54px;
      border: 1px solid #E3E7EC;
      // margin-right: -1px;
    }
  }

  .access_bottom {
    font-size: 12px;
    margin-top:4px;
    display: flex;
    justify-content:space-between;
    span{
      font-size: 13px;
      color: #428BE5;
      cursor: pointer
    }
  }

  .avatar_bg{
    display: flex;
    justify-content: center;
    align-items: center;

    .avatarBox{
       width: 120px;
       height: 120px;
       border-radius: 50%;
       background: linear-gradient(180deg, #8ABAFF 0%, #2F83FF 100%);
       position: relative;
       display: flex;
       justify-content: center;
       align-items: center;
       background-size: cover;
       background-repeat: no-repeat;
       .person_img{
        width: 36px;
        height: 36px;
       }
       .upload_wrap{
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 42px;
        height: 42px;
        background: #1872F6;
        cursor: pointer;
/* White */
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .Icon_ca{
          padding-bottom: 4px;
          img{
            width: 15px;
            height: 15px;
          }
        }
       }
    }
  }

  .loading_spin {
    @centerBox();
    height: 100%;
    position: absolute;
    left: 50%;
    // width: 100%;
    // background-color: rgba(106, 132, 169, 0.2);
  }

  .ant-input-group.ant-input-group-compact {
    display: flex;

  }
  .ant-form-item{
    margin-bottom: 0px !important;
  }
  .phone.ant-form-item{
    margin-bottom: 20px !important;
    .ant-input{
      border-right:1px solid #E3E7EC !important;
      border-left: none !important;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    .ant-input-prefix{
    border: 1px solid #E3E7EC !important;
    border-right: none !important;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-right: 0px;
    width: 80px;
    // padding: 0 6px;
    .areaCodel{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .anticon{
        margin-left: 3px;
      }
    }
    }
  }
 /*  .ant-form-item-explain-error{
    position: absolute;
    bottom: -20px;
  } */
  .verificationCode {
     border: 1px solid #E3E7EC !important;
     border-radius: 8px;
     margin-bottom: 20px !important;
     .verificationCode_text{
      height: 100%;
      padding-right: 20px;
      display: flex;
      height: 54px;
      align-items: center;
      justify-content: flex-end;
      color: #1872F6;
      cursor: pointer;
      span{
        display: inline-block;
        height: 34px;
        line-height: 34px;
        white-space: nowrap;
        border-radius: 6px;
        padding: 0 6px;
        &:hover{
           background: #E8F1FF;
        }
      }
     }
  }
  .verificationCode .ant-input{
    border: none !important;
  }

  .passwardClass .ant-form-item-explain-error{
    position: absolute;
    bottom: -40px;
  }
  .passwardClass.infoInput .ant-input-suffix{
    border-right: 1px solid  #E3E7EC !important;
  }
  .ant-form-item-with-help .ant-form-item-explain{
    height: auto;
    min-height: 0px;
  }

  .ant-input,
  .ant-select,
  .ant-input-password,
  .ant-input-suffix {
    font-size: 14px;
    height: 54px;
    border: 1px solid #E3E7EC !important;
    border-radius: 8px;
  }
  .ant-input-password{
    border-top: none !important;
    border-left: none !important;
    //  border-top-right-radius: 0px !important;
     border-top-left-radius: 0px !important;

  }
  .ant-input-password .ant-input{
    border-right: none !important;
    // border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

  }
  .ant-select{
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    color: #222;
    font-size: 14px;
  }
  .ant-select-selector{
    height: 54px !important;
    display: flex;
    align-items: center;
  }
  .ant-input{
    border-right: none !important;
     border-top-right-radius: 0px !important;
     border-bottom-right-radius: 0px !important;
  }

  .ant-input-suffix {
    margin-left: -1px;
    border-right: none !important;
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .ant-input-affix-wrapper {
    padding: 0;
  }

  .ant-btn-primary {
    width: 100%;
    height: 54px;
    border-radius: 8px;
    background-color: #86B7FF;
    border: 0px;
    font-size: 16px;
    box-shadow: none !important;
    &:hover{
      background-color: #1872F6;
    }
  }
  .registerNow.ant-btn-primary{
    background-color: #fff;
    color: #1872F6;

    &:hover{
      background-color: #E8F1FF;
    }
  }

  .ant-form-item-label > label {
    font-size: 13px;
  }
  .ant-select-arrow{
    right: 20px;
  }
  .ant-select-selection-item{
    padding-right: 30px !important;
  }
  .passwardClass .ant-input{
    padding-left: 20px!important;

  }
  .passwardClass  .ant-input-suffix{
    padding: 0 20px !important;
  }

  .ant-checkbox-wrapper {
    font-size: 12px;
  }

  .diver {
    // border-right: 1px solid #bbbfc3;
    margin: 12px 0;
  }

  .no_mb {
    margin-bottom: 0;
  }

  .primary {
    color: @activeColor;
    cursor: "pointer";
  }
}

.top_bar{
  @vCenterBox();
  max-height:28px;
  min-height: 28px;
  flex-direction: row-reverse;
  padding-right: 12px;
  -webkit-app-region: drag;
  // background-color: #f7f7f7;
  img{
    padding-left: 12px;
    cursor: pointer;
    -webkit-app-region: no-drag;
  }
}
.ant-image-preview-operations{
  padding-top:28px
}
