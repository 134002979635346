.vjs-big-play-button{
    width: 46px !important;
    height: 46px !important;
    margin-left:-23px !important;
    margin-top: -23px !important;
    border-radius: 50% !important;
    border: none !important;
    background-color: rgba(0,0,0,0.4) !important;
}

@primary-color: #428BE5;