.VideoBox_wrap{
  position: relative;
  height: 158px;
  width: 158px;
  &_size{
    background-color: rgba(0,0,0, 0.4);
    height: 18px;
    // line-height: 18px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 18px;
    padding: 0 8px 0px 12px;
    color: #fff;
    font-size: 12px;
    img{
      width: 12px;
      height: 12px;
      margin-bottom: 2px;
      margin-right: 5px;
    }
  }
  &_player{
    width: 40px;
    height: 40px;
    background-color: rgba(0,0,0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    cursor: pointer;
    z-index: 9;
    img{
      width: 24px;
      height: 24px;
    }
  }
  .video{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 158px;
    height: 158px;
    object-fit: cover;
  }
}


.VideoChatBox_wrap{
  // min-height: 200px;
  width: auto;
  position: relative;
  border-radius: 6px;
  &_size{
    background-color: rgba(0,0,0, 0.4);
    height: 18px;
    // line-height: 18px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: 18px;
    padding: 0 8px 0px 12px;
    color: #fff;
    font-size: 12px;
    img{
      width: 12px;
      height: 12px;
      margin-bottom: 2px;
      margin-right: 5px;
    }
  }
  &_player{
    width: 40px;
    height: 40px;
    background-color: rgba(0,0,0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    cursor: pointer;
    z-index: 9;
    img{
      width: 24px;
      height: 24px;
    }
  }
  .chatVideo{
    // position: relative;
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 6px;
  }
}

@primary-color: #428BE5;