.msgclass {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px dashed #8E9298;
}

.msgclass span {
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;;
  border-radius: 30px;
  justify-content: center;
  cursor: pointer;
}

.msgtop {
  color: orange;
  border: 1px solid orange
}

.msgnoread {
  color: red;
  border: 1px solid red;
}

.msgreaded {
  color: #8E9298;
  border: 1px solid #8E9298;
}

.msghover {
  font-size: 24px !important;
  padding: 10px 15px;
}
