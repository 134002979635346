.msg_item_menu {
  width: 200px;

  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 8px;

      &-content {
        padding: 8px 6px;

        .msg_menu_iem {
          @vCenterBox();
          height: 48px;
          padding: 0px 8px;
          border-radius: 6px;
          cursor: pointer;
          color: #000;
          font-size: 16px;

          img {
            margin-right: 20px;
            width: 22px;
            height: 22px;

          }

          &:hover {
            background-color: #eaeaea;
          }
        }
      }
    }
  }
}

.cve_item_menu {
  width: 230px;
  position: relative;
  .ant-popover-content {
    width: 230px;
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 8px;

      &-content {
        padding: 4px 0;

        .item {
          @vCenterBox();
          padding: 6px 12px;
          // border-radius: 6px;
          height: 48px;
          cursor: pointer;
          font-size: 16px;
          img{
            margin-right: 20px;
            width: 24px;
            height: 24px;
          }

          &:hover {
            background-color: #eaeaea;
          }
        }
      }
    }
  }
}

.group_user_list_item_menu{
  width: 230px;
  display: flex;
  flex-direction: column;
  position: relative;
   .ant-popover-content {
    position: absolute;
    bottom: -50px;
    width: 230px;
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border-radius: 8px;

      &-content {
        padding: 4px 0;
        .group_user_list_item {
          @vCenterBox();
          padding: 0px 12px;
          height: 48px;
          z-index: 99;
          cursor: pointer;
          font-size: 16px;
          img{
            width: 24px;
            height: 24px;
          }
          span{
            margin-left: 12px;
            display: block;
            height: 48px;
            line-height: 48px;
          }
          &:hover {
            background-color: #eaeaea;
          }
        }
      }
    }
  }
}

.atContent{
  width: 535px;
  display: flex;
  flex-direction: column;
  position: relative;
   .ant-popover-arrow {
      display: none;
    }
  .ant-popover-inner{
    box-shadow: none;
    border-radius: 10px;
  }
  .ant-popover-inner-content{
    padding: 10px 0px;
  }
  &_list{
    &_item{
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      &:hover{
        background: #F1F2F5;
      }
    }
  }
}
