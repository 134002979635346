.FilesList_wrap{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &_item{
    width: 158px;
    margin-right: 3px;
    margin-bottom: 3px;
    &:nth-child(3n+3){
      margin-right: 0px;
    }
    :global{
      .ant-image-img{
       object-fit: cover;
      }
    }

  }
  &_textItem{
    display: flex;
    margin: 0 8px;
    padding: 0 14px;
    align-items: center;
    width: 100%;
    height: 74px;
    border-radius: 10px;
    &_content{
      margin: 0 10px;
      display: flex;
      flex-direction: column;
      div{
        font-size: 16px;
        color: #000;
        &:nth-child(2){
          font-size: 14px;
          margin-top: 4px;
        }
      }
    }
    &_right{
      margin-left: auto;
      &_text_time{
        height: 74px;
        padding-top: 15px;
        font-size: 12px;
        color: #888;
      }
    }
    &:hover{
      background: #F1F2F5;
    }
  }

  &_itemfile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    margin: 0 8px 10px;
    border-radius: 10px;
    background-color: #fff;
    height: 70px;
    &:hover{
      background-color: #F1F2F5;
    }
    .file_container{
      display: flex;
      align-items: center;
      img{
        margin-right: 10px;
        width: 54px;
        height: 54px;
      }
      .file_info{
        div{
          &:nth-child(2){
            font-size: 14px;
            color: #8E9298;
            span{
              &:nth-child(2){
                margin: 0 10px;
              }
            }
          }
       }

      }
    }
    .download{
      cursor: pointer;
      padding-right: 6px;
      :global{
        .anticon-download{
          font-size: 22px;
          color: #8E9298;
          &:hover{
            color: #1872F6;
          }
        }
      }
    }
  }
}

.empty_wrap{
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img{

  }
}

@primary-color: #428BE5;