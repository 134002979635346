.top_tools {
  display: flex;
  flex-direction: column;
  // padding: 15px 15px 11px;
  padding: 0 8px;
  -webkit-app-region: no-drag;

  :global {
    .ant-input-affix-wrapper {
      border-radius: 10px;
      height: 40px;
      border: none !important;
      background-color: #F1F2F5;
      -webkit-app-region: no-drag;
    }
    .ant-btn{
      -webkit-app-region: no-drag;
    }
    .ant-input{
      background-color: #F1F2F5;
    }
    .ant-input-prefix{
      color: #8E9298;
    }
  }
}

.top_toolBox{
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: space-between;
}

.top_toolBox_right{
  display: flex;
  align-items: center;


}

.contactsImg{
  cursor: pointer;
}

.bar_dropdown{
  background-color: #fff !important;
  border-radius: 8px;
  // .ant-dropdown{
    .ant-dropdown-menu{
      background-color: #fff !important;
      border-radius: 8px;
    }
  // }
}

:global {
  .ant-dropdown-arrow {
    border-color: #fff !important;
  }
  .ant-dropdown-menu{
      background-color: #fff !important;
      border-radius: 8px;
    }

  .ant-dropdown-menu-title-content {
    color: #fff;
  }

  .ant-dropdown-menu-item {
    padding: 7px 14px !important;

    &-active {
      background-color: #4f545d !important;
    }
  }
}

.btn_menu {
  background-color: #353940 !important;
  padding: 0 !important;
  border-radius: 4px !important;
  overflow: hidden;
}

@primary-color: #428BE5;