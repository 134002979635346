.add_cons_modal {
  border-radius: 8px;
  .ant-modal-content{
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-modal-header {
    padding:18px 20px 12px;
    background-color: #fff;
    border-bottom: none;
    display: flex;
    justify-content: center;
    font-size: 16px;
    .ant-modal-title{
      width: 100%;
      text-align: center;
    }
    .sendMsgTitle{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;

      img{
        position: absolute;
        left:0px;
        cursor: pointer;
        top:  -7px;
        width: 40px;
        height: 40px;
      }

    }
  }
  .ant-modal-body{
    padding-bottom: 6px;
    padding-top: 10px;
  }
  .ant-modal-footer {
    padding: 8px 20px;
  }
  .ant-input-affix-wrapper{
     border-radius: 8px;
     height: 54px;
  }
  .ant-modal-footer{
    border-top: none;
    padding-bottom: 10px;
    .add_cons_modal_noMsg{
       color: #C1C4C8;
       font-size: 14px;
       text-align: center;
       height: 72px;
       line-height: 72px;
    }
    .add_cons_modal_person{
      display: flex;
      align-items: center;
      height: 72px;

      img {
        width: 54px;
        height: 54px;
        line-height: 54px;
        font-size: 27px;
        min-width: 54px;
        border-radius: 54px;
      }

      &_info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 10px;
        &_title{
          color: #000;
          font-size: 16px;
        }
        &_id{
          color: #666;
          font-size: 14px;
        }
      }

      .add_cons_modal_btn{
        margin-left: auto;
        border-radius: 8px;
        border-color: #1872F6;
        color: #1872F6;
        height: 36px;
      }
    }

  }
  .ant-input {

    // border: 1px solid #E3E7EC;
    border-radius: 8px;
  }

  &_noMsg{

  }
  &_btn {
    // border-radius: 6px !important;
    // padding: 0px 12px !important;
    // height: 30px !important;
  }
}

.group_modal {
  .group_info_item {
    display: flex;
    padding: 8px 0;
    .group_info_label {
      min-width: 100px;
    }
    .group_info_icon {
      margin-left: 12px;
      font-size: 12px;
      color: @activeColor;
      cursor: pointer;
    }
    .select_box {
      display: flex;
      width: 100%;
      height: 44vh;
      border: 1px solid #c7c7c8;
      border-radius: 6px;
      // height: 80%;
      &_left {
        flex: 1;
        padding: 12px;
        border-right: 1px solid #c7c7c8;
        overflow: auto;
        &_title {
          font-size: 12px;
          margin-top: 8px;
          margin-bottom: 4px;
          .index_tab {
            color: #c7c7c8;
            cursor: pointer;
          }
        }
        &_item {
          @vCenterBox();
          justify-content: space-between;
          padding: 6px 0;
          .left_title {
            font-size: 12px;
            @vCenterBox();
            span {
              margin-left: 6px;
            }
          }
          .title {
            display: inline-block;
            font-size: 12px;
            margin-left: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
            max-width: 120px;
            transform: translateY(4px);
          }
        }
      }
      &_right {
        flex: 1;
        padding: 12px;
        overflow: auto;
        &_title {
          font-size: 12px;
          padding-bottom: 12px;
        }
        &_item {
          @vCenterBox();
          justify-content: space-between;
          padding-bottom: 10px;
          .select_info {
            @vCenterBox();
            &_title {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
  .group_info_footer {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      border-radius: 6px;
      padding: 0 16px;
      height: 26px;
      font-size: 13px;
    }
    .ant-btn-primary {
      margin-left: 12px;
    }
  }
  .ant-input-affix-wrapper {
    padding: 0;
    margin-bottom: 8px;
    border-radius: 6px;
    .ant-input-prefix {
      margin: 0 6px;
    }
  }
  .ant-input {
    border-radius: 4px;
    height: 28px;
    font-size: 13px;
  }
}

.warning_modal {
  .ant-modal-content {
    border-radius: 8px;
    .ant-btn {
      padding: 0 22px;
      border-radius: 6px;
      height: 28px;
    }
  }
}

.transfer_modal {
  .gutter_row {
    max-height: 360px;
    overflow-y: auto;
    .member_item {
      @vCenterBox();
      flex-direction: column;
      padding: 12px 0;
      .member_nick {
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        overflow: hidden;
        max-width: 100%;
      }
    }
  }
}

.mer_modal{
  height: 70vh;
  .ant-modal-header{
    border: none;
  }
  .ant-modal-content{
    height: 100%;
    border-radius: 4px;
    .ant-modal-body{
      padding: 0 0;
      height: calc(100% - 54px);
    }
  }
}

.card_cons_se{
  .ant-modal-content{
    border-radius: 4px;
  }
  .ant-modal-header{
    border: none;
    border-radius:4px 4px 0 0;
  }
  .ant-modal-body{
    padding: 0;
    padding-bottom: 4px;
    .top_ctx{
      padding: 0 24px;
      .ant-input-affix-wrapper{
        padding: 3px 6px;
        border-radius: 4px;
      }
    }
    .btm_ctx{
      .title{
        padding: 12px 24px;
      }
      .cons_list{
        height: 40vh;
        overflow-y: auto;
        &_item{
          @vCenterBox();
          justify-content: space-between;
          padding: 8px 24px;
          &_left{
            @vCenterBox();
            .nick{
              margin-left: 12px;
              max-width: 160px;
              white-space: nowrap;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .ant-btn{
            border-radius: 4px;
          }
          &:hover{
            background-color: @focusColor;
          }
        }
      }
    }
  }
}

.share_modal{
  .ant-modal-content{
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-modal-header{
     padding: 20px 8px 0px;
     border-bottom: none;

    .share_modal_title{
      display: flex;
      align-items: center;
      height: 40px;
      &_icon{
        img{
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        margin: 0 10px;
      }
      &_text{
        font-size: 14px;
        color: #8E9298;
        font-weight: 400;
      }
    }
  }

  .ant-modal-body{
      display: flex;
      flex-direction: column;
      padding: 0 8px;
      max-height: 70vh;
      overflow: auto;
      border-radius: 8px;
      .share_modal_item{
        height: 62px;
        display: flex;
        align-items: center;
        padding: 4px 12px;
        border-radius: 10px;
        &_focus{
          background: #F1F2F5;
        }
        &:hover{
          background: #F1F2F5;
        }
        &_info{
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          &_title{
            color: #000;
            font-size: 16px;
          }
          &_sub{
            color: #666;
            font-size: 14px;
          }
        }
      }
    }
    .ant-modal-footer{
      border-top: none;
       .transfer_footer{
          display: flex;
          align-items: center;
          justify-content: center;
      }
    }

}



.visitingCard_modal{

  .ant-modal-content{
     width: 420px;
     border-radius: 8px;
     overflow: hidden;
  }
  .ant-modal-header{
    border-bottom: none;
    padding: 20px 20px 10px;
  }
    .draggable_card_title{
      display: flex;
      width: 100%;
      align-items: center;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      justify-content: center;
      position: relative;
      &_limg{
        position: absolute;
        left: 0px;
        top: -8px;
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
      &_img{
        position: absolute;
        right: 0px;
        top: -3px;
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
    }

    &_content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &_name{
        color: #000;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
      }
    }

    .ant-modal-body{
      padding: 8px 20px;
    }

    .ant-modal-footer{
      border-top: none;
      .ant-btn{
        width: 100%;
        border-radius: 8px;
        height: 36px;
      }
    }
}

.ant-image-preview-mask{
 background-color: rgba(0, 0, 0, 0.8) !important;
}


.areaCodeModal{
  width: 420px !important;
  .ant-modal-content{
    border-radius: 8px;
    width: 420px;
    .ant-input-affix-wrapper {
      height: 40px;
      background: #F1F2F5;
      border-radius: 10px;
      padding: 0px 10px;
      .ant-input{
        height: 38px;
        background: #F1F2F5;

      }
    }
  }
  .ant-modal-confirm-btns{
    display: none;
  }
  &_header{
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    .anticon{
      // margin-right: auto;
      cursor: pointer;
    }
  }
  &_content{
    display: flex;
    flex-direction: column;
    &_list{
      // display: flex;
      // flex-direction: column;
      margin-top: 10px;
      height: 400px;
      overflow: auto;
      &_item{
        display: flex;
        justify-content: space-between;
        height: 40px;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;
        &:hover{
          background-color:  #F1F2F5;
        }

        div{
          &:nth-child(1){
            font-size: 14px;
            color: #000;
          }
          &:nth-child(2){
            color: #888;
            font-size: 14px;
          }
        }
      }
    }
  }
}



.meeting_modal{
  height: 580px;
  .ant-modal-content{
    height: 580px;
    border-radius: 10px;
    // background-color: #000;
  }
  .ant-modal-body{
    padding: 0px;
    height: 100%;
  }

  .TR{
    height: 100%;
    position: relative;
    .mockComponent{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

    }
    &_Body{
      height: 100%;
      &_Block{
        height: 100%;
      }
    }
    .B{
      position: absolute;
      width: 120px;
      height: 200px;
      bottom: 120px;
      right: 20px;
      &_Body{
        height: 100%;
      }
      &_Body_Block{
        width: 100%;
        height: 100%;
      }
    }
  }

  .mockComponent{
    background-size: 100% 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: #fff;
    position: relative;
    z-index: 9;
    // justify-content: space-between;
    &_top{
      display: flex;
      width: 100%;
      padding-right: 20px;
      padding-top: 30px;
      flex-direction: row-reverse;
      img{
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    &_namebox{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 60px;
      align-items: center;
      justify-content: center;
      div{
        &:nth-child(1){
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        &:nth-child(2){
          font-size: 16px;
        }
      }
    }

    &_bottom{
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 30px;
      position: absolute;
      bottom: 0px;
      &_item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:nth-child(1){
          margin-right: 30px;
        }
    }
     &_img{
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background-color: #FF1F8A;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }

  }
}


.muteConfirmBox{
  width: 256px !important;
  border-radius: 8px;
  overflow: hidden;
  .ant-modal-content{
     border-radius: 8px;
  }
  .ant-modal-header{
    border-bottom: none;
  }
  .ant-modal-title{
    text-align: center;
    font-weight: 500;
    font-size: 16px;
  }
  .ant-modal-footer{
    border-top: none;
    .transfer_footer{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-radio-wrapper{
    height: 54px;
    border-radius: 10px;
    display: flex;
    align-content: center;
    line-height: 54px;
    padding: 0 16px;
    &:hover{
      background-color: #F1F2F5;
    }
    span{
      font-size: 16px;
    }


          .ant-radio-inner {
              transition: none !important;
               margin-right: 8px;
               width: 20px;
               height: 20px;
               font-size: 20px !important;
              &::after{
                transition: none !important;
              }
            }
           .ant-radio-checked{
              transition: none !important;
              &::after{
                animation: none !important;
                display: none !important;
              }
              .ant-radio-inner{
                transition: none !important;
                background-color: #1872F6 !important;
                width: 20px;
                height: 20px;
                font-size: 20px !important;
                  &::after{
                    transition: none !important;
                    background-color:#1872F6 !important;
                    content: "";
                    width: 9px;
                    height: 6px;
                    background-image: url('../assets/images/vector.png');
                    background-size: 100% 100%;
                    margin: 0px !important;
                    transform: translate(-50%,-50%);
                  }
                }
           }
  }
}

.showQcodeBox{
   width: 450px !important;
  .showQcodeWidget{
    display: flex;
    flex-direction: column;
    align-items: center;
      .avatar{
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow:hidden;
      background-size: 100% 100%;
    }
    .avatarBox_title{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin: 14px 0px;
    }
    .qcode_box{
      width: 204px;
      height: 204px;

    }

  }

  .showQcodeBox_footer{
    width: 100%;
   height: 36px;
  background: #0060ED;
  border-radius: 8px;
  .ant-btn{
    width: 100%;
    text-align: center;
    span{
      color: #fff;

    }
  }
  }

}





.assistant{
  .ant-modal-content{
    border-radius: 8px;
    overflow: hidden;
  }
  .ant-modal-header{
     padding: 20px 8px 0px;
     border-bottom: none;

    .assistant_title{
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding-right: 10px;
      &_icon{
        img{
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        margin: 0 10px;
      }
      &_text{
        font-size: 16px;
        color: #000;
        font-weight: 500;
      }
      &_select{
        color: #1872F6;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }

  .ant-modal-body{
    padding: 10px 20px;
    .ant-input-affix-wrapper {
      border-radius: 10px;
      height: 40px;
      border: none !important;
      background-color: #F1F2F5;
      -webkit-app-region: no-drag;
    }
    .ant-btn{
      -webkit-app-region: no-drag;
    }
    .ant-input{
      background-color: #F1F2F5;
    }
    .ant-input-prefix{
      color: #8E9298;
    }
    }
    .ant-modal-footer{
      border-top: none;
       .transfer_footer{
          display: flex;
          align-items: center;
          justify-content: center;
      }
    }

  &_content{
    max-height: 380px;
    overflow: auto;
    &::-webkit-scrollbar {
     display: none;
    }
    &_item{
        height: 62px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        border-radius: 10px;
        margin-bottom: 10px;
        &_check{

        }
        &_focus{
          background: #F1F2F5;
        }
        &:hover{
          background: #F1F2F5;
        }
        &_info{
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          &_title{
            color: #000;
            font-size: 16px;
          }
          &_sub{
            color: #666;
            font-size: 14px;
          }
        }
      }
  }

  .ant-radio-wrapper{
    height: 54px;
    border-radius: 10px;
    display: flex;
    align-content: center;
    line-height: 54px;
    padding: 0 10px;
    &:hover{
      background-color: #F1F2F5;
    }
    span{
      font-size: 16px;
    }
          .ant-radio-inner {
              transition: none !important;
              //  margin-right: 8px;
               width: 20px;
               height: 20px;
               font-size: 20px !important;
              &::after{
                transition: none !important;
              }
            }
           .ant-radio-checked{
              transition: none !important;
              &::after{
                animation: none !important;
                display: none !important;
              }
              .ant-radio-inner{
                transition: none !important;
                background-color: #1872F6 !important;
                width: 20px;
                height: 20px;
                font-size: 20px !important;
                  &::after{
                    transition: none !important;
                    background-color:#1872F6 !important;
                    content: "";
                    width: 9px;
                    height: 6px;
                    background-image: url('../assets/images/vector.png');
                    background-size: 100% 100%;
                    margin: 0px !important;
                    transform: translate(-50%,-50%);
                  }
                }
           }
  }
}




.videoContent{
  position: absolute;
  top: 0px;
  width: 400px;
  height: 580px;
  video{
    width: 400px;
    height: 580px;
    object-fit: cover;
  }
}

