@import "~antd/dist/antd.less";
@import "@/styles/custom.less";
@import "@/styles/card.less";
@import "@/styles/cve.less";
@import "@/styles/drawer.less";
@import "@/styles/modal.less";
@import "@/styles/pop.less";
@import "@/styles/meeting.less";
@import "@/styles/test.less";

.ant-avatar-square {
  border-radius: 6px;
}

//body {
//  user-select: none;
//}
.bighongbao {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: calc(100% + 176px);
  background: rgba(0,0,0,.3);
  //打开、领取红包
  .hongbaowindow {
    position: absolute;
    width: 280px;
    height: 357px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    background: url(../assets/images/hongbao-lq.png) 0 0 no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    .name {
      padding: 18% 0 2%;
      font-weight: bold;
    }
    .remark {
      font-size: 14px;
    }
    .amount {
      font-size: 20px;
      padding: 30% 0 1%;
      font-weight: bold;
    }
    .tip {
      font-size: 12px;
      color: #f9a9ba;
    }
    .name2 {
      padding: 18% 0 2%;
      font-weight: bold;
      color: #000;
    }
    .hongbao_btn_open {
      position: absolute;
      left: 50%;
      top: 60%;
      transform: translateX(-50%);
      z-index: 1002;
      img {
        width: 60%;
        cursor: pointer;
      }
    }
  }
  //已领取
  .hongbao_record {
    position: absolute;
    width: 375px;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    background: #f6f6f8 url(../assets/images/hongbao-ylq.png) 0 0 no-repeat;
    background-size: 100%;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    color: #000;
    .header_line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10% 16px 11% 10px;
      .icon_back {
        width: 10px;
        height: 10px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(225deg);
        cursor: pointer;
      }
      .icon_list {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        i {
          display: block;
          background: #fff;
          width: 3px;
          height: 3px;
          border-radius: 100px;
          margin: 0 2px;
          cursor: pointer;
        }
      }
    }
    .hongbao_head_img {
      background: #f6f6f8;
      width: 32px;
      height: 32px;
      padding: 10px;
      margin: 0 auto;
      box-sizing: content-box;
      border-radius: 100px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
      margin-top: -17px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
      }
    }
    .name {
      font-size: 16px;
      padding: 5px 0 2px;
    }
    .remark {
      font-size: 12px;
      color: #b2b2b2;
      padding-bottom: 70px;
    }
    .hongbao_list {
      align-items: center;
      width: 100%;
      padding: 8px 14px 8px 6px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
      text-align: left;
      height: 320px;
      overflow-y: auto;
      .hongbao_head_img {
        background: #f6f6f8;
        width: 32px;
        height: 32px;
        padding: 10px;
        border-radius: 100px;
        margin: 0 10px 0 0;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
        img {
          width: 100%;
          height: 100%;
          border-radius: 100px;
        }
      }
      .hongbao_item {
        flex: 1;
        .hongbao_item_p {
          font-size: 12px;
          color: #bababa;
          border-bottom: 1px solid #eee;
          padding-bottom: 3px;
          margin-bottom: 3px;
        }
        .hongbao_item_flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .hongbao_item_name {
            font-size: 14px;
            color: #000;
          }
          .hongbao_item_time {
            font-size: 12px;
            color: #b1b1b1;
          }
          .hongbao_item_amount {
            font-size: 12px;
            color: #000;
            font-weight: bold;
          }
        }
      }
    }
  }
  // 发送红包
  .hongbao_fasong {
    position: absolute;
    width: 375px;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    background: #f6f6f8;
    text-align: center;
    font-size: 18px;
    color: #000;
    .header_line {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 40px 15px 50px;
      .title {
        position: absolute;
        top: -5px;
        left: 0;
        z-index: -1;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
      }
      .icon_back {
        width: 13px;
        height: 12px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          vertical-align: text-top;
        }
      }
      .icon_list {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          display: block;
          background: #000;
          width: 3px;
          height: 3px;
          border-radius: 100px;
          margin: 0 2px;
          cursor: pointer;
        }
      }
    }
    .hongbao-form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e9e9eb;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
      padding: 10px 15px;
      font-size: 14px;
      color: #000;
      margin: 0 20px 15px;
      border-radius: 6px;
      input {
        flex: 1;
        text-align: right;
        background: transparent;
        border: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &::placeholder {
          font-size: 12px;
          color: #b1b1b1;
        }
      }
    }
    .sibling-class {
      margin: 0 20px 10px;
    }
    .amount {
      font-size: 26px;
      font-weight: bold;
      padding-bottom: 5px;
      margin-top: 75px;
    }
    .hongbao_btn {
      width: 42%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 6px;
      background: #f74e55;
      font-size: 13px;
      color: #fff;
      border: 1px solid #f74e55;
      padding: 0;
    }
    .tip {
      position: absolute;
      bottom: 10%;
      left: 0;
      z-index: 1;
      width: 100%;
      font-size: 12px;
      color: #646262;
    }
  }
  // 请输入支付密码
  .hongbao_modal_mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1002;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.2);
    .hongbao_modal {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1003;
      width: 100%;
      height: 50%;
      border-radius: 8px;
      background: #fff;
      .header_line {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 20px 30px;
        .title {
          position: absolute;
          top: -5px;
          left: 0;
          z-index: -1;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
        }
        .icon_back {
          width: 13px;
          height: 12px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            vertical-align: text-top;
          }
        }
        .icon_list {
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            display: block;
            background: #000;
            width: 3px;
            height: 3px;
            border-radius: 100px;
            margin: 0 2px;
            cursor: pointer;
          }
        }
      }
      .text {
        font-size: 15px;
        color: #000;
        padding-bottom: 88px;
      }
      .item_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 25px;
        font-size: 12px;
        .item_flex {
          cursor: pointer;
          img {
            width: 14px;
            height: 14px;
          }
          .icon_arrow_down {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #666666;
            border-right: 2px solid #666666;
            transform: rotate(135deg);
            vertical-align: 1px;
          }
        }
      }
      .password-box {
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #cbc5c5;
          width: 40px;
          height: 40px;
          text-align: center;
          font-size: 20px;
          color: #000;
          margin: 0 3px;
          border-radius: 6px;
          border: none;
          outline: none;
          line-height: normal;
        }
      }
      .set-password-box{
        margin-bottom: 20px;
      }
    }
  }
  // 红包记录
  .hongbao_record_wrap {
    position: absolute;
    width: 375px;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    background: #f6f6f8;
    font-size: 14px;
    color: #000;
    .header_line {
      position: relative;
      background: #f65646;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      .icon_back {
        position: absolute;
        top: 25px;
        left: 15px;
        z-index: 1002;
        width: 10px;
        height: 10px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(225deg);
        cursor: pointer;
      }
      .icon_list {
        position: absolute;
        left: 336px;
        width: 18px;
        cursor: pointer;
      }
      .title {
        color: #fff;
        font-weight: bold;
      }
    }
    .hongbao_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      padding-top: 30px;
      background: #f65646;
      margin-top: -1px;
      > div {
        margin: 0 10px 1px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
      }
      .active {
        color: #fff;
        border-bottom-color: #fff;
      }
    }
    .hongbao_record_list {
      margin: 5px 10px 0;
      max-height: 64vh;
      overflow-y: auto;
      .list_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 6px 10px;
        border-bottom: 1px solid #f0f0f2;
        .name {
          font-size: 12px;
          color: #000;
        }
        .time {
          font-size: 12px;
          color: #757373;
        }
        .amount {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}


@primary-color: #428BE5;